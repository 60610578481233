<template>
  <div>
    <component-preview />
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
const ModuleEditor = createNamespacedHelpers("ModuleEditor");

export default {
  data() {
    return {};
  },
  methods: {
    ...ModuleEditor.mapMutations(["setComponentId"])
  },
  mounted() {
    this.setComponentId(this.$route.query.id);
  },
  components: {
    ComponentPreview: () => import("@/components/editor/ComponentPreview")
  }
};
</script>

<style lang="scss" scoped></style>
